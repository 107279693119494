<div bsModal #ndaModal="bs-modal" class="modal fade modal-otp" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content" data-track-region="OTP">
      <div class="modal-header">
        <h3 *ngIf=isSendOtpScreen class="ally-primary-heading pull-left"> {{appStrings.modalOtp.otpOneTimeSecurityCode}} </h3>
        <h3 *ngIf=!isSendOtpScreen class="ally-primary-heading pull-left">{{appStrings.modalOtp.otpEnterSecurityCode}}</h3>
        <button type="button" class="close pull-right text-primary" aria-label="Close" (click)="hide()">
          <i class="allycon-glyph-close"></i>
        </button>
      </div>

      <div class="modal-body">
        <div *ngIf="isModalLoaded && isSendOtpScreen">
          <section class="otp-coachmark">
            <i class="allycon-info-fill text-ally"></i>
            <span>{{ appStrings.modalOtp.otpCoachMarkText }}</span>
          </section>

          <p>{{appStrings.modalOtp.otpMustVerify}}</p>

          <strong *ngIf="showSMSFactor">{{appStrings.modalOtp.otpConfirmSecurityCode}}</strong>
          <strong *ngIf="!showSMSFactor">{{appStrings.modalOtp.otpConfirmEmail}}</strong>

          <form [attr.allytmfn]="'otpSendSecCodeForm'" #otpSendSecCode="ngForm" class="form-horizontal" name="otpSendSecCode">
            <div *ngIf="!showSMSFactor">
              <div class="form-group row" data-private>
                <div class="col-lg-12 col-md-12 col-xs-12">
                  {{showEmailId | maskEmail }} ({{appStrings.modalOtp.emailFactor}})
                </div>
              </div>
              <div *ngIf="sendOtpErr?.length>0" class="form-group row">
                <div class="col-lg-12 col-md-12 col-xs-12">
                  <p class="text-danger text-bold">
                    <span class="allycon-error-fill"></span> {{sendOtpErr}}
                  </p>
                </div>
              </div>
            </div>
            <div *ngIf="showSMSFactor" [class.has-default-selection]="hasDefaultSelection" data-private>
              <label *ngIf="!isEditProfile" class="custom-control custom-radio ml-3" [hidden]="isRegistration && !isDefaultSelectionSMS">
                <input [attr.allytmfn]="'factorChoice'" type="radio" name="factorChoice" [(ngModel)]="factorChoice" [value]="smsFactorId" class="custom-radio custom-control-input" id="smsFactor">
                <span for="smsFactor" class="custom-control-label">{{ userMobileNumber | maskPhone }} ({{appStrings.modalOtp.smsFactor}})</span>
              </label>

              <label *ngIf="!isEditProfile" class="custom-control custom-radio ml-3" [hidden]="isRegistration && isDefaultSelectionSMS">
                <input [attr.allytmfn]="'factorChoice'" type="radio" name="factorChoice" [(ngModel)]="factorChoice" [value]="emailFactorId" class="custom-radio custom-control-input" id="emailFactor">
                <span for="emailFactor" class="custom-control-label text-lower">{{ showEmailId | maskEmail }} ({{appStrings.modalOtp.emailFactor}})</span>
              </label>

              <label *ngIf="isEditProfile" class="custom-control custom-radio ml-3">
                <input [attr.allytmfn]="'factorChoice'" type="radio" name="factorChoice" [(ngModel)]="factorChoice" [value]="smsFactorId" class="custom-control-input" id="smsFactor">
                <span for="smsFactor" class="custom-control-label">{{ userMobileNumber | maskPhone }} ({{appStrings.modalOtp.smsFactor}})</span>
              </label>

              <div *ngIf="showSendError" class="form-group row">
                <div class="col-lg-12 col-md-12 col-xs-12">
                  <p class="text-danger text-bold">
                    <span class="allycon-error-fill"></span>{{appStrings.modalOtp.sendError}}
                  </p>
                </div>
              </div>
            </div>
            <div class="form-group row my-4">
              <div class="col-lg-12 col-md-12 col-xs-12 accessibility-link-group">
                {{appStrings.modalOtp.otpTechIssueText}}
                <a href="tel:{{appStrings.modalOtp.otpDealerHelpDeskNo}}">
                  <strong>{{appStrings.modalOtp.otpDealerHelpDeskNo}}</strong>.
                </a>
              </div>
            </div>
            <div class="form-group row mb-0">
              <div class="col-lg-12 col-sm-12 col-xs-12">
                <button type="submit" class="btn btn-primary font-weight-bold" (click)="sendOtp()">{{appStrings.modalOtp.otpSendSecCodeBtn}}</button>
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="isModalLoaded && !isSendOtpScreen">
          <p>
            <span class="allycon-sm-success-fill greentick-icon"></span>{{appStrings.modalOtp.otpSentSecCodeTxt}}</p>
          <p class="text-bold">{{appStrings.modalOtp.otpEnterCodeTxt}}</p>
          <p>{{appStrings.modalOtp.otpCodeWillTxt}}
            <strong>{{appStrings.modalOtp.otpExpireMinTxt}}</strong> {{appStrings.modalOtp.otpAfterSentTxt}}</p>
          <p class="text-bold" data-private>
            {{ appStrings.modalOtp.otpCodeSentTxt }}:
            <span *ngIf="isFactorSelectedEmail" class="text-lower">
              {{ factorSelected.profile.email | maskEmail }}
              <span class="text-normal">({{ appStrings.modalOtp.emailFactor }})</span>
            </span>
            <span *ngIf="!isFactorSelectedEmail && !!userMobileNumber" class="text-lower">
              {{ userMobileNumber | maskPhone }}
              <span class="text-normal">({{ appStrings.modalOtp.smsFactor }})</span>
            </span>
            <span *ngIf="shouldDisplaySendAlternateCode" (click)="sendOtpAlternate()" class="text-link">{{ displayOtpSendAlternateCode }}</span>
          </p>

          <div>
            <form [attr.allytmfn]="'otpValSecCodeForm'" #otpValSecCode="ngForm" (ngSubmit)="otpValSecCode.valid && submitOtp()" class="form-horizontal" name="otpValSecCode">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-xs-12">
                  <div *ngIf="(otpNumber.dirty || isSubmitClicked) && otpNumber.errors?.required">
                    <p class="text-danger text-bold">
                      <span class="allycon-error-fill"></span> {{appStrings.modalOtp.enterOtpNumber}}
                    </p>
                  </div>
                  <div *ngIf="otpNumber.errors?.pattern">
                    <p class="text-danger text-bold">
                      <span class="allycon-error-fill"></span> {{appStrings.modalOtp.mustSixDigit}}
                    </p>
                  </div>
                  <div *ngIf="otpNumber.errors?.minlength && !otpNumber.errors?.pattern">
                    <p class="text-danger text-bold">
                      <span class="allycon-error-fill"></span> {{appStrings.modalOtp.sixNumbers}}
                    </p>
                  </div>
                  <div *ngIf="otpNumber.valid">
                    <div *ngFor="let errorQry of responseQry">
                      <p class="text-danger text-bold">
                        <span class="allycon-error-fill"></span> <span [innerHTML]="errorQry"></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row otp-passcode">
                <div class="col-lg-4 col-sm-4 col-xs-12">
                  <label class="text-bold">{{appStrings.modalOtp.otpSecCodeTxt}}</label>
                  <input  [attr.allytmfn]="'otpNumber'" #otpCode type="text" name="otpNumber" pattern={{otpPattern}} [class.is-invalid]="isSubmitClicked && hasOtpSubmissionException"
                         class="form-control basic-input" [(ngModel)]="otpModel.otpNumber" (keypress)="enterOtpNo($event)" #otpNumber="ngModel" [attr.allytm-private]="true"
                         minlength="6" maxlength="6" autoFocus required />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-lg-12 col-sm-12 col-xs-12">
                  <button type="submit" (click)="otpSubmit()" class="btn btn-primary font-weight-bold">{{appStrings.modalOtp.otpSubmitBtn}}</button>
                  <button *ngIf="canResendOTP" type="button" (click)="sendOtp()" class="btn btn-link accessibility-button-group">{{appStrings.modalOtp.otpResendSecCodeTxt}}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
