<header>
  <nav>
    <h1 class="text-ally">{{CONSTANTS.hello}} {{ fullName }}</h1>
    <p class="secondary-info" *ngIf="isDealershipUser">{{ dealershipDetails }}</p>
    <p class="secondary-info" *ngIf="isBranchUser">{{ allyRole }}</p>

    <ul class="content-links accessibility-link-group">
      <li routerLinkActive="hide">
        <a routerLink="/dashboard">{{ CONSTANTS.myTools }}</a>
      </li>
      <li routerLinkActive="hide">
        <a routerLink="/manage/profile">{{ CONSTANTS.editProfile }}</a>
      </li>
      <li routerLinkActive="hide" *ngIf="showManage">
        <a routerLink="/manage/users">{{ CONSTANTS.manageUser }}</a>
      </li>
    </ul>
  </nav>

  <form [attr.allytmfn]="'searchForm'" #search="ngForm" name="search" (ngSubmit)="canSubmit && handleSubmit(query)"
    class="col-lg-4 row justify-content-end">
    <div class="form-group col col-lg-10 px-0 accessibility-form-input-group">
      <label for="search-ally-dash">{{ CONSTANTS.searchLabel }}</label>
      <div class="control-wrapper">
        <div class="input-group">
          <input [attr.allytmfn]="'searchAllyDash'" #keywordInput="ngModel" id="search-ally-dash" class="form-control" [(ngModel)]="query" name="query" type="search" required
            minlength="3" [class.is-invalid]="!isValidQuery" data-public>
          <span class="input-group-append">
            <input [attr.allytmfn]="'submit'" class="btn btn-outline-default btn-icon-search" type="submit">
          </span>
          <div *ngIf="!isValidQuery">
            <p class="text-danger text-bold">
              {{ CONSTANTS.searchErrorMessage}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </form>
</header>