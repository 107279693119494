import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PaginationModule,  } from 'ngx-bootstrap/pagination';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule  } from 'ngx-bootstrap/collapse';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgIdleModule } from '@ng-idle/core';

import { AppRoutingModule } from './app-routing.module';
import { ApiGatewayModule } from './api-gateway/api-gateway.module';
import { EmptyResponseModule } from "./empty-response/empty-response.module";
import { LoadingSpinnerModule } from './loading-spinner/loading-spinner.module';
import { ComponentsCommonModule } from './components-common/components-common.module';
import { OtpModule } from './otp/otp.module';
import { DrawerModule } from './drawer/drawer.module'
import { PasswordComplexityModule } from './password-complexity/password-complexity.module';
import { SessionModule } from './session/session.module';
import { ErrorsModule } from './errors/errors.module';
import { ContactsModule } from './contacts/contacts.module';
import { ChangePasswordPreLoginModule } from './change-password-pre-login/change-password-pre-login.module';
import { ModalErrorForgotPasswordComponent } from './forgot-password/components/modal-error-forgot-password/modal-error-forgot-password.component';

import { AppGuard } from './guards/app.guard';
import { RegisterGuard } from './registration/guards/register.guard';
import { CookieModule } from 'ngx-cookie';
import { OktaListApplicationsService } from './services/okta-list-applications.service';
import { BookmarkingService } from './services/bookmarking.service';
import { DealerAppInTabService } from './services/dealer-app-in-tab.service';
import {
  AppIdleService,
  AnalyticsService,
  AppConstantsService,
  HttpErrorHandlerService,
  OktaAuthService,
  UsStatesService,
  StoreService,
  PasswordExpiryService,
} from './services';

import { UserService } from './services/user/user.service';
import { AdminListService } from './services/admin-list/admin-list.service';

import { AutofocusDirective } from './directives/auto-focus.directive';

import {
  PendingChangesGuard, ManageUsersGuard
} from './guards';

import { AppComponent } from './app.component';
import { NdaHeaderComponent } from './components/nda-header/nda-header.component';
import { NdaLoginComponent } from './nda-login/nda-login.component';
import { NdaNavComponent } from './components/nda-nav/nda-nav.component';
import { NdaNoPageComponent } from './components/nda-no-page/nda-no-page.component';
import { NdaOperationHoursComponent } from './components/nda-operation-hours/nda-operation-hours.component';
import { StandardPageLayoutComponent } from './components/standard-page-layout/standard-page-layout.component';
import { DealershipCancelModalComponent } from './registration/components/dealership-cancel-modal/dealership-cancel-modal.component';
import { DealershipMismatchedErrorModalComponent } from './registration/components/dealership-mismatched-error-modal/dealership-mismatched-error-modal.component';
import { AllyCancelModalComponent } from './registration-ally/components/ally-cancel-modal/ally-cancel-modal.component';
import { SecurePageComponent } from './components/secure-page/secure-page.component';
import { NdaWarningModalComponent } from './components-common/nda-warning-modal/nda-warning-modal.component';
import { LogoutComponent } from './components/logout/logout.component';
import { PasswordWarnComponent } from './components/password-warn/password-warn.component';
import { PAGE_SERVICE_PROVIDER } from './services/page/page.service';
import { NdaChatService } from './services/chat/nda-chat.service';
import { NdaChatComponent } from './components/nda-chat/nda-chat.component';
import { NdaPopoverComponent } from './components/nda-popover/nda-popover.component';
import { AuthenticationService } from './services/authentication.service';
import { SessionManagementService } from './services/session-management.service';
import { ComingSoonSplash } from './dashboard/components/splash/coming-soon-splash.component';
import { LaunchDarklyService } from './services/launchdarkly/launchdarkly.service';
import { ErrorResponseInterceptor } from './interceptor/error-response.interceptor';
import { AppListService } from './services/app.list.service';
import { LogRocketService } from './services/logrocket/logrocket.service';


// a global pageService object
declare let lpTag: Object;

@NgModule({
  declarations: [
    AppComponent,
    NdaChatComponent,
    NdaHeaderComponent,
    NdaNavComponent,
    NdaLoginComponent,
    NdaOperationHoursComponent,
    NdaNoPageComponent,
    StandardPageLayoutComponent,
    DealershipCancelModalComponent,
    DealershipMismatchedErrorModalComponent,
    AllyCancelModalComponent,
    SecurePageComponent,
    NdaWarningModalComponent,
    LogoutComponent,
    PasswordWarnComponent,
    NdaPopoverComponent,
    AutofocusDirective,
    ModalErrorForgotPasswordComponent,
    ComingSoonSplash
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ErrorsModule,
    EmptyResponseModule,
    ApiGatewayModule,
    AppRoutingModule,
    OtpModule,
    DrawerModule,
    SessionModule,
    ContactsModule,
    ChangePasswordPreLoginModule,
    NgIdleKeepaliveModule.forRoot(),
    CookieModule.forRoot(),
    AlertModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    NgIdleModule.forRoot(),
    LoadingSpinnerModule.forRoot(),
    PasswordComplexityModule,
    ComponentsCommonModule
  ],
  providers: [
    AppConstantsService,
    PasswordExpiryService,
    StoreService,
    AppIdleService,
    AnalyticsService,
    SessionManagementService,
    AuthenticationService,
    HttpErrorHandlerService,
    OktaAuthService,
    PendingChangesGuard,
    UsStatesService,
    AppGuard,
    RegisterGuard,
    ManageUsersGuard,
    OktaListApplicationsService,
    BookmarkingService,
    DealerAppInTabService,
    UserService,
    PAGE_SERVICE_PROVIDER,
    NdaChatService,
    AdminListService,
    LaunchDarklyService,
    AppListService,
    LogRocketService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorResponseInterceptor,
      multi:true
    }
  ],
  entryComponents: [
    DealershipCancelModalComponent,
    AllyCancelModalComponent,
    NdaWarningModalComponent,
    ModalErrorForgotPasswordComponent,
    DealershipMismatchedErrorModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }