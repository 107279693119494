import * as tslib_1 from "tslib";
import { environment } from "../../../environments/environment";
import * as LogRocket from "logrocket";
var LogRocketService = /** @class */ (function () {
    function LogRocketService() {
        // Characters that show up when PII is replaced.
        this.mask = "***";
        // List of Dash service layers.
        this.serviceLayers = [
            "workflowservices",
            "userservices",
            "dealerservices",
            "sessionservices",
            // Include calls made to OKTA.
            "dealeriam",
            // Include calls made to DSR.
            "dealer/user",
            "dealer/data",
            // Include Launch Darkly calls.
            "launchdarkly",
            // Include tagging script calls.
            "smetrics",
        ];
        // Complete list of fields to be masked in any request or response payload.
        this.fieldsToMask = new Set([
            // Omit first name from PII fields.
            // "firstName",
            "userId",
            "lastName",
            "email",
            "mobile",
            "mobilePhone",
            "userName",
            "requestedBy",
            "managerEmail",
            "managerId",
            "adminFirstName",
            "adminLastName",
            "adminEmail",
            "password",
            "oldEmail",
            "oldPassword",
            "newPassword",
            "cuid",
            "login",
            "stateToken",
            "passCode",
            "sid",
            "sessionToken",
            "sessionId",
            "adminUsername",
            "adminFirstName",
            "adminLastName",
            "phoneNumber",
            "ph",
            "faxNumber",
            "contactName",
            "secondEmail",
            "requestTransactionIdentifier",
            "updatedBy",
            "username",
            "auctionAccessId",
        ]);
        // List of endpoints potentially containing username PII.
        this.endpointsToMaskURL = ["/workflow/users", "/user"];
        // Alphanumeric characters of length between 6 and 8 characters preceded by /users/ or /user/
        this.usernamePathRegex =
            /(\/users?\/)([A-Z0-9]{6}|[a-zA-Z0-9]{8})(?![a-zA-Z0-9])/g;
        // Alphanumeric characters of length between 6 and 8 characters preceded by userName=
        this.usernameQueryParamRegex = /(userName=)[a-zA-Z0-9]{6,8}/g;
        // "auctionaccessid%" (case insensitive) followed by any number of numeric characters closed by a non-alphanumeric character
        this.auctionAccessIdRegex = /(auctionaccessid%)([a-zA-Z0-9]+)(?=\W)/gi;
    }
    /*
      Called in: ally-nda-angular\src\app\guards\app.guard.ts
  
      Initializes LogRocket when user lands on the login page.
      Identifying the user happens later.
    */
    LogRocketService.prototype.initLogRocket = function () {
        var _this = this;
        LogRocket.init(environment.logRocket.appId, {
            network: {
                requestSanitizer: function (request) {
                    return _this.sanitizeNetworkPII(request);
                },
                responseSanitizer: function (response) {
                    return _this.sanitizeNetworkPII(response);
                },
            },
            // Used to keep <iframe> apps in the same session recording.
            mergeIframes: true,
            /*
              Parent app must explicitly point to <iframe> apps on different domains.
              <iframe> apps require LogRocket SDK installed and initialized in *their* application code.
              Read more here: https://docs.logrocket.com/reference/mergeiframes
            */
            childDomains: [
                environment.logRocket.iframeURLs.docManagerApps,
                environment.logRocket.iframeURLs.dsrApps,
            ],
            /*
              Obfuscates all user inputs fields from the session recording with placeholder text.
              Other non-input fields containing PII must be explicitly targeted using: data-private
              Read more here: https://docs.logrocket.com/reference/dom
            */
            dom: {
                inputSanitizer: "lipsum",
                // Hides DOM elements by CSS name. Used for hiding Chat.
                privateClassNameBlocklist: ["lp_maximized", "lpChat", "lp_desktop"],
            },
        });
    };
    /*
      Called in: ally-nda-angular\src\app\dashboard\components\nda-dashboard-container\nda-dashboard-container.component.ts
  
      Identifies the user to LogRocket after the full auth flow has concluded
      and the user lands on the Dashboard page.
    */
    LogRocketService.prototype.identifyLogRocketUser = function (user) {
        LogRocket.identify(user.userId, {
            name: user.userId + " - " + user.userType,
            firstName: user.firstName,
            username: user.username,
            userType: user.userType,
        });
    };
    /*
      Called for requests/responses detected by LogRocket.
  
      Request/Response headers are masked for every network call.
  
      Subsequent functions are called under two scenarios.
        1. Current endpoint is the token endpoint or the OKTA /me endpoint.
        2. Current endpoint is a specific tagging endpoint.
        3. Current endpoint belongs to one of our Dash/OKTA/DSR services.
    */
    LogRocketService.prototype.sanitizeNetworkPII = function (data) {
        var maskedData = tslib_1.__assign({}, data);
        // Sanitize request headers for any request.
        maskedData.headers = this.sanitizeHeaders(data.headers);
        // Mask everything for token request and OKTA me request.
        if (data.url.includes("token") || data.url.includes("sessions/me")) {
            maskedData.body = null;
        }
        else if (
        // Efficiently check whether current URL is one of the Dash services.
        this.serviceLayers.some(function (service) { return data.url.includes(service); })) {
            maskedData.url = this.sanitizeURLPII(data.url);
            maskedData.body = this.sanitizeBodyPII(data.body);
        }
        return maskedData;
    };
    /*
      Iterates over the entire payload for a given request/response if present.
      If a key within "fieldsToMask" is found in the payload, its value is replaced with "this.mask".
    */
    LogRocketService.prototype.sanitizeBodyPII = function (body) {
        var _this = this;
        // Check if body contains any value. Skip iteration if not.
        if (!!body) {
            var jsonObject = void 0;
            try {
                // Parse the JSON string to an object.
                jsonObject = JSON.parse(body);
            }
            catch (error) {
                // If JSON string is not valid, return body without modification.
                return body;
            }
            // Recursive function to traverse and mask the JSON object.
            var maskValues_1 = function (obj) {
                if (typeof obj !== "object" || obj === null) {
                    return;
                }
                if (Array.isArray(obj)) {
                    // If the object is an array, iterate over its elements.
                    return obj.map(function (item) { return maskValues_1(item); });
                }
                else {
                    // If the object is not an array, iterate over its properties.
                    for (var key in obj) {
                        if (obj.hasOwnProperty(key)) {
                            if (_this.fieldsToMask.has(key)) {
                                // Replace the values of any matching PII keywords with mask.
                                obj[key] = _this.mask;
                            }
                            else if (typeof obj[key] === "object") {
                                // Traverse all objects within payload.
                                maskValues_1(obj[key]);
                            }
                        }
                    }
                }
            };
            maskValues_1(jsonObject);
            return JSON.stringify(jsonObject);
        }
        else {
            return body;
        }
    };
    /*
      Masks PII within request URLs.
      
      Covers these primary cases.
        1. The request URL contains the sessionId attribute.
        2. The request URL contains the user's username as a query parameter.
        3. The request URL contains an auctionAccessId parameter.
        4. The request URL contains the user's username in the path.
    */
    LogRocketService.prototype.sanitizeURLPII = function (url) {
        var e_1, _a;
        var _this = this;
        if (url.includes("/refresh")) {
            // Filters session ID.
            var sessionPath = "/session/";
            var sessionIndex = url.indexOf(sessionPath) + sessionPath.length;
            return "" + url.substring(0, sessionIndex) + this.mask + "/refresh";
        }
        else if (url.includes("userName=")) {
            // Filters username query parameter.
            return url.replace(this.usernameQueryParamRegex, function (match, p1) {
                return p1 + _this.mask;
            });
        }
        else if (url.includes("smetrics.ally.com")) {
            // Filters auction access ID.
            return url.replace(this.auctionAccessIdRegex, this.mask);
        }
        try {
            // Check if the URL contains any of the endpoints with username parameters.
            for (var _b = tslib_1.__values(this.endpointsToMaskURL), _c = _b.next(); !_c.done; _c = _b.next()) {
                var endpoint = _c.value;
                if (url.includes(endpoint)) {
                    // Replace alphanumeric series of 6-8 characters with astericks.
                    url = url.replace(this.usernamePathRegex, function (match, p1) {
                        return p1 + _this.mask;
                    });
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return url;
    };
    /*
      Removes any PII/sensitive information from request and response headers.
      Headers are case-sensitive, and will sometimes appear with capitalization.
    */
    LogRocketService.prototype.sanitizeHeaders = function (headers) {
        return tslib_1.__assign({}, headers, { Authorization: null, Cookie: null, "gateway-header": null, CSRFChallengeToken: null, authorization: null, "api-key": null, appuserloginid: null, appusersessionid: null, "set-cookie": null, Username: null, username: null, "user-name": null, userName: null, "true-client-ip": null, "x-cache-key": null, "x-cache-key-extended-internal-use-only": null, "x-true-cache-key": null });
    };
    return LogRocketService;
}());
export { LogRocketService };
