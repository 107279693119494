<div class="container">
  <header>
    <h2 class="title text-ally">{{ CONSTANTS.header1 }}</h2>
  </header>
  <section class="p-0 pt-2">
    <p class="col-lg-8 col-md-12 p-0" [innerHTML]="CONSTANTS.secondaryHeader3"></p>
    <article class="row">
      <div class="col-lg-6 col-md-9">
        <form [attr.allytmfn]="'passwordForm'" [formGroup]="passwordForm" (submit)="onSubmit()" class="form-horizontal">

          <div class="form-group">
            <label for="enter-current-password" class="col-md-5">{{CONSTANTS.expiredPassword}}</label>
            <div class="control-wrapper col-md-7">
              <input [attr.allytmfn]="'oldPassword'" type="password" class="form-control" formControlName="oldPassword" id="enter-current-password" [class.is-invalid]="showOldPasswordError || showInvalidTemporaryOrExpiredPasswordError || isPasswordHasRestrictedCharactersInOldPassword" [attr.allytm-error]="oldPasswordErrorMessage" pattern="{{pwdPattern}}" [attr.allytm-private]="true" data-private>
              <span class="invalid-feedback" *ngIf="showOldPasswordError">{{CONSTANTS.errors.currentPassword}}</span>
              <span class="invalid-feedback" *ngIf="showInvalidTemporaryOrExpiredPasswordError">{{CONSTANTS.errors.invalidCurrentPassword}}</span>
              <span class="invalid-feedback" *ngIf="isPasswordHasRestrictedCharactersInOldPassword">{{CONSTANTS.errors.invalidPassword}}</span>

            </div>
          </div>

          <div class="form-group">
            <label for="enter-new-password" class="col-md-5">{{CONSTANTS.newPassword}}</label>
            <div class="control-wrapper col-md-7">
              <input [attr.allytmfn]="'newPassword'" type="password" class="form-control" formControlName="newPassword" id="enter-new-password"
              [class.is-invalid]="showEmptyNewPasswordError || showGenericNewPasswordError || showNewPasswordMatchesWithOldPasswordHistoryError || isPasswordHasRestrictedCharactersInNewPassword" [attr.allytm-error]="newPasswordErrorMessage(passwordForm.controls.newPassword.errors?.passwordMatchesUsername)" pattern="{{pwdPattern}}" [attr.allytm-private]="true" data-private>
              <span class="invalid-feedback" *ngIf="showEmptyNewPasswordError">{{CONSTANTS.errors.newPasswordRequired}}</span>
              <span class="invalid-feedback" *ngIf="showGenericNewPasswordError">{{CONSTANTS.errors.newPasswordGeneric}}</span>
              <span class="invalid-feedback" *ngIf="showNewPasswordMatchesWithOldPasswordHistoryError">{{CONSTANTS.errors.newPasswordMatchesPreviousPassword}}</span>
              <span *ngIf="passwordForm.controls.newPassword.errors?.passwordMatchesUsername && isSubmitClicked" class="invalid-feedback"> {{ CONSTANTS.errors.passwordMatchesUsername }} </span>
              <span class="invalid-feedback" *ngIf="isPasswordHasRestrictedCharactersInNewPassword">{{CONSTANTS.errors.invalidPassword}}</span>
            </div>
          </div>

          <div class="col-12 d-xs-block d-md-none">
            <div class="form-group">
              <div class="control-wrapper offset-md-4 col-md-8">
                <app-password-complexity [password]="passwordForm.controls.newPassword.value"></app-password-complexity>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="re-enter-new-password" class="col-md-5">{{CONSTANTS.reEnterPassword}}</label>
            <div class="control-wrapper col-md-7">
              <input [attr.allytmfn]="'reEnterNewPassword'" type="password" id="re-enter-new-password" pattern="{{pwdPattern}}" (paste)="$event.preventDefault()" class="form-control" formControlName="passwordConfirmation" id="password-confirmation" [class.is-invalid]="showReEnterPasswordError" [attr.allytm-error]="reEnterNewPasswordErrorMessage" [attr.allytm-private]="true" data-private>
              <span class="invalid-feedback" *ngIf="showReEnterPasswordError">{{CONSTANTS.errors.reEnterPassword}}</span>
              <span class="invalid-feedback" *ngIf="isPasswordHasRestrictedCharactersInNewConfirmPassword">{{CONSTANTS.errors.invalidPassword}}</span>
            </div>
          </div>

          <div class="form-group">
            <div class="control-wrapper offset-md-5 col-md-7">
              <button type="submit" class="btn btn-primary font-weight-bold col-xs-12 col-sm-4 col-lg-4" id="changePasswordSubmit">{{CONSTANTS.button.submit}}</button>
            <button type="button" class="btn btn-link accessibility-button-group col-xs-12 col-sm-4 col-lg-4" id="changePasswordCancel" (click)="cancelForm()">{{ CONSTANTS.button.cancel}}</button>
            </div>
          </div>

        </form>
      </div>
      <div class="col-lg-3 col-md-3 mt-5 d-none d-md-block">
          <app-password-complexity [password]="passwordForm.controls.newPassword.value"></app-password-complexity>
        </div>
    </article>
  </section>
</div>
<modal-password-changed #passwordChanged></modal-password-changed>
