<div class="container" data-track-name="Login">
  <h2 class="title text-ally d-inline">{{AppStrings.login.NDAtitleLogin}}</h2>

  <div class="row accessibility-link-group">
    <div class="col-lg-6">
      <div class="text-danger text-bold mt-3">
        <div *ngFor="let msg of preAuthFormErrors">
          <span class="allycon-error-fill"></span> {{ msg }}
        </div>

        <div *ngIf="(loginForm.valid || isExternalAuth) && customErrorShow" class="mt-3">
          <div *ngFor="let msg of this.errorMessage">
            <span class="allycon-error-fill"></span> {{ msg }}<br>
          </div>
        </div>
      </div>

      <form [attr.allytmfn]="'loginForm'" #loginElement #loginForm="ngForm" class="form-horizontal" (ngSubmit)="loginForm.valid && authenticateUser()" name="loginForm" [attr.allytm-error]="loginFormErrorMessage()">
        <div class="form-group loginForm">
          <div class="control-wrapper ml-0 accessibility-form-input-group">
            <label class="ml-0" for="username-input">{{AppStrings.login.NDAlabelUid}}</label>
            <div class="row">
              <div class="col-md-7">
                <input [attr.allytmfn]="'username'" (keypress)="_restrictUserid($event)" (keyup)="credentialsChanged()" id="username-input" type="text" pattern={{uidPattern}} [class.error]="isSubmitClicked"  #user="ngModel" [(ngModel)]="model.userId" name="user" class="form-control" required [class.is-invalid]="isSubmitClicked && user.invalid && !isExternalAuth" />
              </div>
              <div class="col-md-5 ">
                <button id="forgot-username-btn" type="button" class="btn btn-link btn-forgot-username accessibility-button-group border-0 p-0 text-link text-bold lg-" data-track-elem="button" data-track-name="Forgot Username" (click)="showForgotUsernameModal(forgotUsernameTemplate)">{{AppStrings.login.forgotUsername}}</button>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group loginForm">
          <div class="control-wrapper ml-0 accessibility-form-input-group">
            <label class="ml-0" for="password-input">{{AppStrings.login.NDAlabelPwd}}</label>
            <div class="row">
              <div class="col-md-7">
                <input [attr.allytmfn]="'password'" (keyup)="credentialsChanged()" id="password-input" type="password" #password="ngModel" pattern="{{pwdPattern}}" [(ngModel)]="model.pwd" name="password" class="form-control" required minlength="8" [class.is-invalid]="isSubmitClicked && password.invalid && !isExternalAuth" [attr.allytm-private]="true" data-private/>
              </div>
              <div class="col-md-5 text-bold">
                <a routerLink="/gethelp">{{AppStrings.login.forgotPassword}}</a>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group mt-4">
            <div class="control-wrapper col-md-12 offset-md-2 ml-0 accessibility-form-input-group">
              <button type="submit" class="btn btn-primary font-weight-bold" (click)="submitClicked()" id="login-btn" data-track-name="Login" data-track-elem="button" data-track-trigger="login">{{AppStrings.login.NDABtnLogin}}</button>
              <p class="mt-5">{{AppStrings.login.notRegistered}} <a class=" text-bold" routerLink="/register" id="gotoPdnVerification">{{AppStrings.login.requestUsername}}</a></p>
              <p class="mt-2">{{AppStrings.login.lookingFor}}
                <a class="text-bold" href={{saLoginLink}} data-track-elem="link" data-track-trigger="external" data-track-name="SmartAuction">{{AppStrings.application.names.smartAuction}}</a> or
                <a class="text-bold" href={{allyAcademyURL}} data-track-elem="link" data-track-trigger="external"  data-track-name="Ally Academy">{{AppStrings.dashboard.myTools.allyAcademy.academyShortTitle}}</a>?</p>
            </div>
        </div>
      </form>
    </div>

    <div class="col-lg-6">
      <nda-operation-hours></nda-operation-hours>
      <template #otpModalContainer></template>
      <div style="width: 20px;">
        <ng-template #forgotUsernameTemplate>
          <div  class="container p-0">
            <div class="modal-header modal-header pt-3 pr-2">
              <h4 tabindex="-1" class="modal-title pull-left text-bold pt-2">{{AppStrings.login.forgotUsername}}</h4>
              <button type="button" class="btn-close allycon allycon-glyph-close text-primary border-0 bg-white"  aria-label="Close" (click)="hideForgotUsernameModal()">
              </button>
            </div>
            <div class="modal-body">
            For help with your username, contact the dealer admin at your dealership.
          </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>